<template>
  <span v-if="isEmpty">
    <slot>
      <span :class="emptyClass"> Empty </span>
    </slot>
  </span>
  <span v-else :class="notEmptyClassComputed"
    ><slot :value="value" name="not-empty">{{ value }}</slot></span
  >
</template>

<script>
import { isEmpty } from '@/utils/utils';

export default {
  props: {
    emptyClass: {
      type: [Array, Object, String],
      default: 'text-gray-400',
    },

    notEmptyClass: {
      type: [Array, Object, String],
      default: null,
    },

    value: {
      default: null,
    },

    preserveSpaces: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isEmpty() {
      return isEmpty(this.value);
    },

    notEmptyClassComputed() {
      if (!this.preserveSpaces) {
        return this.notEmptyClass;
      }

      if (!this.notEmptyClass) {
        return 'text-pre';
      }

      if (typeof this.notEmptyClass === 'string') {
        return `${this.notEmptyClass} text-pre`.trim();
      }

      if (Array.isArray(this.notEmptyClassComputed)) {
        return [...this.notEmptyClass, 'text-pre'];
      }

      if (typeof this.notEmptyClass === 'object') {
        return { ...this.notEmptyClass, 'text-pre': true };
      }

      return 'text-pre';
    },
  },
};
</script>
