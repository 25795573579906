<template>
  <div :style="{ color }" class="spinner-container">
    <div :style="styles" class="spinner" />

    <slot>
      <span v-if="text">
        {{ text }}
      </span>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#dbdbdb',
    },

    diameter: {
      type: [String, Number],
      default: 2,
    },

    thickness: {
      type: Number,
      default: 2,
    },

    text: {
      type: String,
      default: null,
    },
  },

  computed: {
    styles() {
      return {
        border: `${this.thickness}px solid ${this.color}`,
        width: `${this.diameter}em`,
        height: `${this.diameter}em`,
      };
    },
  },
};
</script>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
}

.spinner {
  animation: spinAround 0.5s infinite linear;
  display: inline-block;
  border-radius: 290486px;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  margin: 0 0.5em;
}
</style>
