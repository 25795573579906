<template>
  <div class="columns">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ColumnGroup',
};
</script>
