import get from 'lodash/get';
import set from 'lodash/set';

class FilterConfig {
  constructor() {
    this.userId = undefined;
    this.config = this.getAllFromStorage();
  }

  setUserId(id) {
    this.userId = id;
    this.config = this.getAllFromStorage();
  }

  storageKey() {
    return this.userId ? `filter_config.${this.userId}` : 'filter_config.guest';
  }

  getAllFromStorage() {
    try {
      const config = JSON.parse(window.localStorage.getItem(this.storageKey()));

      return config === null ? {} : config;
    } catch (e) {
      return {};
    }
  }

  get(key, defaultValue = null) {
    return get(this.config, key, defaultValue);
  }

  set(key, value) {
    set(this.config, key, value);

    window.localStorage.setItem(this.storageKey(), JSON.stringify(this.config));

    return this;
  }
}

export default FilterConfig;
