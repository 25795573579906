<template>
  <div v-if="lazy">
    <div v-if="active">
      <slot />
    </div>
  </div>
  <div v-else>
    <div v-show="active">
      <slot />
    </div>
  </div>
</template>

<script>
import kebabCase from 'lodash/kebabCase';

export default {
  name: 'base-tab',

  props: {
    id: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      required: true,
    },

    lazy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      active: false,
    };
  },

  computed: {
    key() {
      return this.id ? this.id : kebabCase(this.title);
    },
  },

  watch: {
    active(val) {
      this.$nextTick(() => (val ? this.$emit('shown') : this.$emit('hidden')));
    },
  },
};
</script>
