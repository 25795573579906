import Vue from 'vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import PortalVue from 'portal-vue';
import * as Sentry from '@sentry/vue';
import VueClipboard from 'vue-clipboard2';
import VueRouter from 'vue-router';
import FloatingVue from 'floating-vue';

Vue.use(PiniaVuePlugin);
Vue.use(PortalVue);
Vue.use(VueClipboard);
Vue.use(VueRouter);
Vue.use(FloatingVue);

const pinia = createPinia();

import './sass/theme/theme.scss';
import './sass/styles.scss';
import App from './App.vue';
import router from './router.js';
import api from './api';
import auth from './utils/authentication';
import AlgoliaSearch from './utils/AlgoliaSearch';
import EventBus from './utils/event-bus';
import FilterConfig from './utils/FilterConfig';
import ViewHistory from './utils/ViewHistory';
import url from './mixins/url';

import './baseComponentsRegistration.js';
import './filters';
import './veeValidate';

Vue.mixin(url);

const filterConfig = new FilterConfig();
const viewHistory = new ViewHistory();

Vue.prototype.$api = api;
Vue.prototype.$auth = auth;
Vue.prototype.$algolia = new AlgoliaSearch({
  applicationId: import.meta.env.VITE_ALGOLIA_APP_ID,
  apiKey: import.meta.env.VITE_ALGOLIA_API_KEY,
});
Vue.prototype.$eventBus = new EventBus();
Vue.prototype.$filterConfig = filterConfig;
Vue.prototype.$viewHistory = viewHistory;

Vue.directive('click-outside', {
  bind: (el, binding, vnode) => {
    el.clickOutsideEvent = (event) => {
      // Check if it was clicked outside of el and its children
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };

    document.body.addEventListener('click', el.clickOutsideEvent);
  },

  unbind: (el) => document.body.removeEventListener('click', el.clickOutsideEvent),
});

Sentry.init({
  Vue,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.feedbackIntegration({
      autoInject: true,
      showBranding: false,
      colorScheme: 'light',
    }),
  ],
});

const app = new Vue({
  pinia,
  router,
  render: (h) => h(App),
});

app.$mount('#app');
