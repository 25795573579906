<template>
  <div v-on="$listeners">
    <notification-box :value="errorMessage" />

    <template v-if="!hasError">
      <loading-spinner v-if="loading" />
      <template v-else>
        <slot />
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'WithLoading',

  props: {
    error: {
      type: [Boolean, Error, String],
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    errorMessage() {
      if (!this.hasError) {
        return null;
      }

      if (typeof this.error === 'boolean') {
        return 'An error has occurred.';
      }

      if (this.error instanceof Error) {
        return this.error.message;
      }

      return this.error;
    },

    hasError() {
      if (typeof this.error === 'boolean') {
        return this.error;
      }

      if (this.error instanceof Error) {
        return true;
      }

      if (typeof this.error === 'string') {
        return this.error.length > 0;
      }

      return !!this.error;
    },
  },
};
</script>
