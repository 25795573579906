<template>
  <div class="box">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseBox',
};
</script>
