import get from 'lodash/get';
import has from 'lodash/has';

class ClientError extends Error {
  constructor(error) {
    super(
      has(error, 'response.data.message') ? get(error, 'response.data.message') : error.message
    );

    this.response = has(error, 'response.status')
      ? {
          body: get(error, 'response.data'),
          status: get(error, 'response.status'),
          headers: get(error, 'response.headers'),
        }
      : null;
  }
}

export default ClientError;
