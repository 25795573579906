<template>
  <div class="control">
    <money
      v-bind="attrs"
      v-model="model"
      :precision="precision"
      :prefix="prefix"
      :class="{
        'is-danger': hasError,
        'input-width-small': smallWidth,
        'text-end': !textLeft,
      }"
      class="input"
    />
  </div>
</template>

<script>
import { Money } from 'v-money';

export default {
  name: 'InputCurrency',

  inheritAttrs: false,

  components: {
    Money,
  },

  props: {
    value: {
      type: Number,
      default: undefined,
    },

    precision: {
      type: Number,
      default: 0,
    },

    prefix: {
      type: String,
      default: '$',
    },

    smallWidth: {
      type: Boolean,
      default: false,
    },

    textLeft: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: [Boolean, String],
      default: false,
    },
  },

  data() {
    return {
      model: this.value === null ? 0 : this.value,
    };
  },

  watch: {
    value(val) {
      this.model = val;
    },

    model(val) {
      this.$emit('input', val);
    },
  },

  computed: {
    attrs() {
      const { prefix, precision, ...attrs } = this.$attrs;

      return attrs;
    },
  },
};
</script>
