import isDate from 'lodash/isDate';
import moment from 'moment-timezone';

import { isArray, isEmpty, isString } from './utils';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MONTHS_SHORT = MONTHS.map((m) => m.substring(0, 3));

export const getCurrentYear = () => new Date().getFullYear();

export const getMonth = (idx) => MONTHS[idx - 1];

export const getMonthShort = (idx) => MONTHS_SHORT[idx - 1];

const isApiUnixDate = (value) => {
  return typeof value === 'string' ? /^\/Date\(-?[0-9]+[-+]\d{4}\)\/$/.test(value) : false;
};

const parseApiUnixDate = (value) => {
  if (!isString(value)) {
    return null;
  }

  const matches = value.match(/^\/Date\((-?[0-9]+)[-+]\d{4}\)\/$/);

  if (isArray(matches) && matches.length >= 2) {
    const momentObj = moment(matches[1], 'x');

    return momentObj.isValid() ? momentObj.toDate() : null;
  }

  return null;
};

export const parseDate = (value, { inputFormat = null, timezone = null } = {}) => {
  if (isEmpty(value)) {
    return null;
  }

  if (isDate(value)) {
    return value;
  }

  if (isApiUnixDate(value)) {
    return parseApiUnixDate(value);
  }

  let momentObj;

  if (inputFormat) {
    momentObj = timezone ? moment.tz(value, inputFormat, timezone) : moment(value, inputFormat);
  } else {
    momentObj = timezone ? moment.tz(value, timezone) : moment(value);
  }

  return momentObj.isValid() ? momentObj.toDate() : null;
};

export const parseApiDate = (value, options = {}) =>
  parseDate(value, {
    ...options,
    timezone: import.meta.env.VITE_TIMEZONE,
  });

export const formatDate = (value, formatStr = 'MM/DD/YYYY', options = {}) => {
  const date = parseDate(value, options);

  return isDate(date) ? moment(date).format(formatStr) : null;
};

export const formatApiDate = (value, formatStr = 'MM/DD/YYYY', options = {}) => {
  const date = parseApiDate(value, options);

  return isDate(date) ? moment(date).format(formatStr) : null;
};

export const dateFromNow = (value, { suffix = true, ...options } = {}) => {
  const date = parseDate(value, options);

  return isDate(date) ? moment(date).fromNow(!suffix) : null;
};

export const apiDateFromNow = (value, { suffix = true, ...options } = {}) => {
  const date = parseApiDate(value, options);

  return isDate(date) ? moment(date).fromNow(!suffix) : null;
};
