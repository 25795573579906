<template>
  <a v-if="isLink" v-on="$listeners" :class="{ 'navbar-link': link }" class="navbar-item">
    <slot />
  </a>
  <div v-else v-on="$listeners" :class="{ 'navbar-link': link }" class="navbar-item">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'NavbarItem',

  props: {
    link: {
      type: Boolean,
      default: false,
    },

    useLink: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isLink() {
      return (
        this.useLink || (this.$attrs.hasOwnProperty('href') && typeof this.$attrs.href === 'string')
      );
    },
  },
};
</script>
