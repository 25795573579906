<script setup>
import Vue, { onMounted, ref } from 'vue';
import { setUser } from '@sentry/vue';
import { useAuthStore } from './stores/auth.js';
import { useRegionsStore } from './stores/regions.js';
import { useModals } from './stores/modals.js';

import api from './api';
import publicApi from './api/publicApi';
import lgdfsApi from './api/lgdfsApi';
import meusApi from './api/meusApi';
import meusCommercialApi from './api/meusCommercialApi';

const loading = ref(true);
const auth = useAuthStore();
const regions = useRegionsStore();
const modals = useModals();

onMounted(async () => {
  if (auth.isLoggedIn) {
    loading.value = true;

    const user = await auth.refresh();

    const res = await regions.load();

    // Set legacy data
    window.user = {
      ...user,
      first_name: user.fname,
      last_name: user.lname,
      email: user.emailaddress,
      level: user.userlevel,
      type: user.usertype,
      office_phone_extension: user.officeextension,
      assigned_region_id: user.regionid,
      associated_region_ids: user.associatedregions,
      associated_region_list: user.regions,
    };

    window.regions = res[0].data.data;
    window.region_groups = res[1].data.data;

    api.setBearerToken(user.api_token);
    publicApi.setBearerToken(user.public_api_token);
    lgdfsApi.setBearerToken(user.lgdfs_api_token);
    meusApi.setBearerToken(user.meus_api_token);
    meusCommercialApi.setBearerToken(user.meus_commercial_api_token);

    Vue.prototype.$auth.setUser(window.user);
    Vue.prototype.$filterConfig.setUserId(user.id);
    Vue.prototype.$viewHistory.setUserId(user.id);

    setUser({
      id: user.id,
      username: `${user.fname} ${user.lname}`,
      email: user.emailaddress,
    });

    loading.value = false;
  } else {
    setUser(null);

    loading.value = false;
  }
});
</script>

<template>
  <div v-if="loading" class="d-flex justify-content-center align-items-center p-3">
    <div class="spinner"></div>
    Loading...
  </div>
  <div v-else style="min-height: 100%">
    <RouterView />
    <div v-for="item in modals.items" :key="item.id" class="modal-wrapper">
      <div class="modal-backdrop" @click="modals.close(item.id)" />
      <component :is="item.component" v-bind="item.props" @close="modals.close(item.id)" />
    </div>
  </div>
</template>
