export const CLAIM_VIEWER = 1;
export const SALES_REP = 2;
export const CLAIM_ADMIN = 3;
export const CSR = 4;
export const CSR_MANAGER = 5;
export const ACTUARY = 6;
export const STARR_UNDERWRITER = 7;
export const CANTALK = 8;
export const SUPER_ADMIN = 10;

export default {
  CLAIM_VIEWER,
  SALES_REP,
  CLAIM_ADMIN,
  CSR,
  CSR_MANAGER,
  ACTUARY,
  STARR_UNDERWRITER,
  CANTALK,
  SUPER_ADMIN,
};
