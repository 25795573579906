<template>
  <div :class="ratioClass" class="field-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FieldGroup',

  props: {
    ratio: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    ratioClass() {
      return this.ratio ? `field-group-${this.ratio}` : '';
    },
  },
};
</script>
