<template>
  <div ref="dialog" v-if="active" class="dialog" style="z-index: 1000">
    <div ref="bg" class="dialog-bg" @click="handleHide" />

    <div class="dialog-wrapper">
      <button :disabled="disabled" type="button" class="delete is-large" @click="handleHide" />

      <button
        v-show="prevItemId"
        :disabled="disabled"
        type="button"
        class="button is-black prev"
        @click="params.itemId = prevItemId"
      >
        <span class="fas fa-arrow-left"></span>
      </button>

      <button
        v-show="nextItemId"
        :disabled="disabled"
        type="button"
        class="button is-black next"
        @click="params.itemId = nextItemId"
      >
        <span class="fas fa-arrow-right"></span>
      </button>

      <header class="dialog-header">
        <slot v-bind="params" name="header">
          <h2>
            <slot v-bind="params" name="title" />
          </h2>
        </slot>
      </header>

      <div class="dialog-body">
        <slot v-bind="params" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    defaultParams: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      active: false,
      params: {},
      prevVisibleBg: null,
    };
  },

  computed: {
    itemIdx() {
      if (this.params.itemId && Array.isArray(this.params.itemIds)) {
        return this.params.itemIds.indexOf(this.params.itemId);
      }

      return -1;
    },

    prevItemId() {
      return this.itemIdx > 0 ? this.params.itemIds[this.itemIdx - 1] : null;
    },

    nextItemId() {
      return this.itemIdx >= 0 && this.itemIdx < this.params.itemIds.length - 1
        ? this.params.itemIds[this.itemIdx + 1]
        : null;
    },
  },

  created() {
    this.$eventBus.on(`${this.id}.show`, this.handleShow);
  },

  watch: {
    active(val) {
      if (val) {
        this.$emit('shown', this.params);

        this.$nextTick(() => {
          const $prevDialogs = [...document.querySelectorAll('.dialog')].filter(
            ($dialog) => $dialog !== this.$refs.dialog
          );

          if ($prevDialogs.length) {
            const maxZIndex = Math.max(
              $prevDialogs.map(($dialog) => parseInt($dialog.style.zIndex, 10))
            );

            this.$refs.dialog.style.zIndex = maxZIndex + 1;
          }
        });
      } else {
        this.$emit('hidden');
      }
    },
  },

  methods: {
    handleShow(params = {}) {
      const e = new Event('show', { cancelable: true });

      this.$emit('show', e, { ...this.defaultParams, ...params });

      if (!e.defaultPrevented) {
        this.show({ ...this.defaultParams, ...params });
      }
    },

    handleHide() {
      if (this.disabled) {
        return;
      }

      const e = new Event('hide', { cancelable: true });

      this.$emit('hide', e);

      if (!e.defaultPrevented) {
        this.hide();
      }
    },

    show(params = {}) {
      this.params = params;
      this.active = true;
    },

    hide() {
      this.active = false;
    },
  },
};
</script>

<style scoped>
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dialog-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.delete {
  position: absolute;
  top: 0;
  right: 0;
}

.prev,
.next {
  position: absolute;
  z-index: 2;
  bottom: -0.5rem;
}

.prev {
  left: -0.5rem;
}

.next {
  right: -0.5rem;
}

@media screen and (min-width: 768px) {
  .prev {
    bottom: 50%;
  }

  .next {
    bottom: 50%;
  }
}

@media screen and (min-width: 1370px) {
  .prev {
    font-size: 1.25rem;
    left: -3.75rem;
  }

  .next {
    font-size: 1.25rem;
    right: -3.75rem;
  }
}

.dialog-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - 2rem);
  max-width: 1200px;
  max-height: calc(100% - 1.5rem);
  margin: 0.5rem auto 1rem auto;
}

.dialog-header {
  background: #fff;
  margin-top: 2.5rem;
  padding: 1rem;
  border-radius: 4px 4px 0 0;
}

.dialog-body {
  background: #fff;
  padding: 1rem;
  overflow-x: auto;
  border-radius: 0 0 4px 4px;
}

h2 {
  font-size: 1.25rem;
  font-weight: 600;
  border-bottom: 1px dotted #b5b5b5;
}
</style>
