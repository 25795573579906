import isDate from 'lodash/isDate';
import isEmptyValue from 'lodash/isEmpty';
import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';

export const isArray = (value) => Array.isArray(value);

export const isBoolean = (value) => typeof value === 'boolean';

export const isMobile = () => window.innerWidth < 1088;

export const isNumber = (value) => typeof value === 'number';

export const isString = (value) => typeof value === 'string';

export const isEmpty = (value) => {
  if (value === undefined || value === null) {
    return true;
  }

  if (isBoolean(value) || isDate(value) || isNumber(value)) {
    return false;
  }

  if (isString(value)) {
    return value.trim().length === 0;
  }

  if (isArray(value)) {
    return value.length === 0;
  }

  return isEmptyValue(value);
};

export const snakeCaseKeys = (obj) => mapKeys(obj, (value, key) => snakeCase(key));

export const formatPhone = (value) => {
  if (isEmpty(value)) {
    return null;
  }

  let phone = value;

  if (isNumber(phone)) {
    phone = phone.toString();
  }

  if (!isString(phone)) {
    return phone;
  }

  const parts = phone.match(/(\d{3})[-]?(\d{3})[-]?(\d{4})/);

  if (!isArray(parts)) {
    return phone;
  }

  return `${parts[1]}-${parts[2]}-${parts[3]}`;
};
