<template>
  <div class="control">
    <the-mask
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      :class="{ 'is-small': small, 'is-danger': !!hasError }"
      class="input"
    />
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';

export default {
  inheritAttrs: false,

  components: {
    TheMask,
  },

  props: {
    value: {
      type: [Number, String],
      default: '',
    },

    small: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: [Boolean, String],
      default: false,
    },
  },
};
</script>
