<template>
  <span v-if="isEmpty(value)" class="text-gray-400"> Empty </span>
  <a v-else :href="`tel:${value}`">
    {{ value }}
  </a>
</template>

<script>
import { isEmpty } from '@/utils/helpers';

export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },

  methods: {
    isEmpty,
  },
};
</script>
