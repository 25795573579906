<template>
  <div class="control">
    <textarea
      ref="input"
      v-bind="$attrs"
      v-on="listeners"
      :value="value"
      :class="{ 'is-danger': hasError }"
      class="textarea"
      @input="$emit('input', $event.target.value)"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: 'InputTextarea',

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: '',
    },

    hasError: {
      type: [Boolean, String],
      default: false,
    },
  },

  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;

      return listeners;
    },
  },

  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
