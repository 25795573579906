<template>
  <div>
    <div :class="{ 'is-boxed': boxed, 'is-small': small }" class="tabs">
      <ul>
        <li
          v-for="tab in tabs"
          :key="tab.key"
          :class="{ 'is-active': tab.key === active }"
          @click="select(tab.key)"
        >
          <a>{{ tab.title }}</a>
        </li>
      </ul>
    </div>
    <slot />
  </div>
</template>

<script>
import queryString from 'query-string';

export default {
  name: 'base-tabs',

  props: {
    boxed: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },

    useUrlHash: {
      type: Boolean,
      default: false,
    },

    urlHashKey: {
      type: String,
      default: 'tab',
    },
  },

  data() {
    return {
      active: null,
      tabs: [],
    };
  },

  mounted() {
    this.tabs = this.$children;
    this.active = this.getUrlHashValue(this.$children[0].key);

    if (this.useUrlHash) {
      window.addEventListener('hashchange', this.select);
    }
  },

  destroyed() {
    if (this.useUrlHash) {
      window.removeEventListener('hashchange', this.select);
    }
  },

  watch: {
    active(val) {
      this.tabs.forEach((tab) => (tab.active = tab.key === val));
    },
  },

  methods: {
    select(tabKey) {
      if (tabKey instanceof HashChangeEvent) {
        this.active = this.getUrlHashValue(this.tabs[0]);
      } else if (this.useUrlHash) {
        window.location.hash = queryString.stringify({
          ...queryString.parse(window.location.hash),
          [this.urlHashKey]: tabKey,
        });
      } else {
        this.active = tabKey;
      }
    },

    getUrlHashValue(defaultValue = null) {
      if (!this.useUrlHash) {
        return defaultValue;
      }

      const value = queryString.parse(window.location.hash)[this.urlHashKey];

      if (typeof value !== 'string') {
        return defaultValue;
      }

      return value.trim().length === 0 ? defaultValue : value.trim().toLowerCase();
    },
  },
};
</script>
