<template>
  <div class="control">
    <datepicker
      v-bind="attrs"
      v-on="listeners"
      :value="value"
      :format="customFormatter"
      :input-class="[
        'input',
        {
          'is-danger': hasError,
          'input-width-small': smallWidth,
        },
      ]"
      @input="input"
    />
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';

export default {
  name: 'InputField',

  inheritAttrs: false,

  components: {
    Datepicker,
  },

  props: {
    value: {
      type: [Date, String],
      default: null,
    },

    format: {
      type: String,
      default: 'MM/DD/YYYY',
    },

    smallWidth: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: [Boolean, String],
      default: false,
    },
  },

  computed: {
    attrs() {
      const { value, format, ...attrs } = this.$attrs;

      return attrs;
    },

    listeners() {
      const { input, ...listeners } = this.$listeners;

      return listeners;
    },
  },

  methods: {
    customFormatter(date) {
      return moment(date).format(this.format);
    },

    input(date) {
      if (date) {
        date.setHours(0, 0, 0, 0);
      }

      this.$emit('input', date);
    },
  },
};
</script>
