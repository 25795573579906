<template>
  <div class="field">
    <label v-if="label" class="label">
      {{ label }}
    </label>
    <slot />
    <p v-if="error" class="help is-danger">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FormField',

  props: {
    label: {
      type: String,
      default: null,
    },

    error: {
      type: String,
      default: null,
    },
  },
};
</script>
