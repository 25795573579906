<template>
  <div class="control">
    <v-select
      ref="select"
      v-bind="attrs"
      :options="options"
      :placeholder="placeholder"
      :value="value"
      :reduce="(option) => option.value"
      label="text"
      @input="(val) => $emit('input', val)"
    />
  </div>
</template>

<script>
import find from 'lodash/find';
import states from '@/enums/states';
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },

  props: {
    value: {
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: 'All States',
    },

    regionId: {
      type: [Number, String],
      default: null,
    },
  },

  computed: {
    attrs() {
      const { value, disabled, placeholder, ...attrs } = this.$attrs;

      return attrs;
    },

    options() {
      let stateCodes = [];

      if (this.regionId) {
        const region = find(window.regions, { regionid: parseInt(this.regionId, 10) });

        stateCodes = region.defaultstates;
      } else {
        if (this.$auth.isSuperAdmin() || this.$auth.user.regions.length === 0) {
          return states;
        }

        stateCodes = this.$auth.user.regions.map((i) => i.defaultstates).flat();
      }

      return states.filter((state) => stateCodes.includes(state.value));
    },
  },
};
</script>
