import ClientError from './ClientError';

class Client {
  constructor(axios) {
    this.axios = axios;
  }

  get(url, config = {}) {
    return this.request({ ...config, url, method: 'get' });
  }

  post(url, data, config = {}) {
    return this.request({ ...config, url, data, method: 'post' });
  }

  put(url, data, config = {}) {
    return this.request({ ...config, url, data, method: 'put' });
  }

  patch(url, data, config = {}) {
    return this.request({ ...config, url, data, method: 'patch' });
  }

  delete(url, data, config = {}) {
    return this.request({ ...config, url, data, method: 'delete' });
  }

  async request(config) {
    try {
      const { data, status, headers } = await this.axios.request(config);

      return { body: data, status, headers };
    } catch (error) {
      throw new ClientError(error);
    }
  }

  setBearerToken(token) {
    this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
}

export default Client;
