import Vue from 'vue';
import VeeValidate, { Validator, ValidationObserver, ValidationProvider } from 'vee-validate';
import moment from 'moment';

Validator.extend('array', {
  getMessage: (field) => `The ${field} must be an array.`,
  validate: (value) => Array.isArray(value),
});

Validator.extend('date_after', {
  getMessage: (field, { targetDate, targetName }) =>
    targetName
      ? `The ${field} must be after ${targetName}.`
      : `The ${field} must be after ${moment(value).format('MM/DD/YYYY')}.`,
  validate: (value, { targetDate }) => {
    if (!moment(targetDate).isValid()) {
      return true;
    }

    return moment(value).isAfter(targetDate);
  },
});

Validator.extend('date_after_or_equal', {
  getMessage: (field, { targetDate, targetName }) =>
    targetName
      ? `The ${field} must be after or equal to ${targetName}.`
      : `The ${field} must be after or equal to ${moment(value).format('MM/DD/YYYY')}.`,
  validate: (value, { targetDate }) => {
    if (!moment(targetDate).isValid()) {
      return true;
    }

    return moment(value).isSameOrAfter(targetDate);
  },
});

Validator.extend('date_before', {
  getMessage: (field, { targetDate, targetName }) =>
    targetName
      ? `The ${field} must be before ${targetName}.`
      : `The ${field} must be before ${moment(value).format('MM/DD/YYYY')}.`,
  validate: (value, { targetDate }) => {
    if (!moment(targetDate).isValid()) {
      return true;
    }

    return moment(value).isBefore(targetDate);
  },
});

Validator.extend('date_before_or_equal', {
  getMessage: (field, { targetDate, targetName }) =>
    targetName
      ? `The ${field} must be before or equal to ${targetName}.`
      : `The ${field} must be before or equal to ${moment(value).format('MM/DD/YYYY')}.`,
  validate: (value, { targetDate }) => {
    if (!moment(targetDate).isValid()) {
      return true;
    }

    return moment(value).isSameOrBefore(targetDate);
  },
});

Validator.extend('phone', {
  getMessage: (field) => `The ${field} is invalid.`,
  validate: (value) => /^\d{3}-\d{3}-\d{4}$/.test(value),
});

Validator.extend('zip', {
  getMessage: (field) => `The ${field} is invalid.`,
  validate: (value) => /^\d{5}(-\d{4})?$/.test(value),
});

Validator.extend('ein', {
  getMessage: (field) => `The ${field} is invalid.`,
  validate: (value) => /^\d{2}-\d{7}$/.test(value),
});

Validator.extend('array_email', {
  getMessage: (field) => `Each ${field} must be a valid email address.`,
  validate: (value) => {
    if (!Array.isArray(value)) {
      return false;
    }

    for (let i = 0; i < value.length; i++) {
      if (!Validator.rules.email.validate(value[i])) {
        return false;
      }
    }

    return true;
  },
});

Vue.use(VeeValidate);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
