<template>
  <input-select v-bind="$attrs" v-on="$listeners" :options="options" />
</template>

<script>
export default {
  props: {
    showAll: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    regions() {
      if (this.$auth.isSuperAdmin()) {
        return window.regions;
      }

      if (this.$auth.isClaimAdmin() && this.$auth.user.regions.length === 0) {
        return window.regions;
      }

      if (this.$auth.isCsr() && this.$auth.user.regions.length === 0) {
        return window.regions;
      }

      return this.$auth.user.regions;
    },

    options() {
      return this.regions.map((i) => ({
        text: i.regionname,
        value: i.regionid,
      }));
    },
  },
};
</script>
