import { ref, markRaw, watch } from 'vue';
import { defineStore } from 'pinia';

export const useModals = defineStore('modals', () => {
  const items = ref([]);
  const counter = ref(0);

  watch(
    () => items.value.length,
    (val) => {
      if (val > 0) {
        document.body.classList.add('modal-active');
      } else {
        document.body.classList.remove('modal-active');
      }
    }
  );

  const open = (component, props) => {
    const id = ++counter.value;

    items.value.push({
      id,
      component: markRaw(component),
      props,
    });

    return id;
  };

  const close = (id) => {
    const idx = items.value.findIndex((item) => item.id === id);

    if (idx !== -1) {
      items.value = [...items.value.slice(0, idx), ...items.value.slice(idx + 1)];
    }
  };

  const withComponent = (component, defaultProps = {}) => {
    return {
      open: (props) => open(component, { ...defaultProps, ...props }),
    };
  };

  return { items, close, withComponent };
});
