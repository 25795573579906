import Vue from 'vue';

class EventBus {
  constructor() {
    this.vue = new Vue();
  }

  on(event, callback) {
    this.vue.$on(event, callback);

    return () => this.off(event, callback);
  }

  off(event, callback) {
    this.vue.$off(event, callback);
  }

  emit(event, ...args) {
    this.vue.$emit(event, ...args);
  }
}

export default EventBus;
