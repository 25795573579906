<template>
  <div :class="{ 'text-start': leftLabel }" class="field-title">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FieldTitle',

  props: {
    leftLabel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
