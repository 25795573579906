<template>
  <div>
    <input-file ref="file" v-bind="$attrs" v-on="listeners" @select="onSelect" />

    <div class="tags">
      <span v-for="(file, idx) in files" :key="idx" class="tag">
        {{ file.name }}
        <button type="button" class="delete is-small" @click="files.splice(idx, 1)"></button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  data() {
    return {
      files: [],
    };
  },

  computed: {
    listeners() {
      const { select, ...listeners } = this.$listeners;

      return listeners;
    },
  },

  methods: {
    reset() {
      this.$refs.file.reset();
    },

    onSelect(files) {
      const newFiles = Array.isArray(files) ? files : [files];

      this.files = [...this.files, ...newFiles];
    },
  },
};
</script>

<style scoped>
.tags {
  margin-top: 0.5rem;
}
</style>
