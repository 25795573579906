<template>
  <div class="column">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ColumnItem',
};
</script>
