<template>
  <a
    v-if="isLink"
    v-bind="attrs"
    :disabled="disabled"
    :target="target"
    :rel="rel"
    :class="classes"
    class="button"
  >
    <slot />
  </a>
  <button
    v-else
    v-bind="attrs"
    v-on="$listeners"
    :disabled="disabled"
    :class="classes"
    class="button"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'BaseButton',

  inheritAttrs: false,

  props: {
    color: {
      type: String,
      default: '',
    },

    size: {
      type: String,
      default: '',
    },

    fullwidth: {
      type: Boolean,
      default: false,
    },

    outlined: {
      type: Boolean,
      default: false,
    },

    rounded: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    link: {
      type: Boolean,
      default: false,
    },

    newTab: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    attrs() {
      const { disabled, target, rel, ...attrs } = this.$attrs;

      return attrs;
    },

    isLink() {
      return this.link || this.$attrs.hasOwnProperty('href');
    },

    disabled() {
      const isDisabled = this.$attrs.disabled === '' ? true : this.$attrs.disabled;

      return isDisabled || this.loading;
    },

    target() {
      return this.newTab ? '_blank' : this.$attrs.target;
    },

    rel() {
      return this.newTab ? 'noopener noreferrer' : this.$attrs.rel;
    },

    classes() {
      return [
        {
          'is-fullwidth': this.fullwidth,
          'is-outlined': this.outlined,
          'is-rounded': this.rounded,
          'is-loading': this.loading,
        },
        this.colorClass,
        this.sizeClass,
      ];
    },

    colorClass() {
      return this.color ? `is-${this.color}` : null;
    },

    sizeClass() {
      return this.size ? `is-${this.size}` : null;
    },
  },
};
</script>
