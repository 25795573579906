<template>
  <div class="field is-horizontal">
    <div v-if="label || noLabel" :class="{ 'text-start': leftLabel }" class="field-label">
      <label class="label">
        <slot name="label">
          {{ label }}
        </slot>
      </label>
    </div>

    <div class="field-body">
      <div class="field">
        <slot />

        <p v-if="error" class="help is-danger">
          {{ error }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormFieldHorizontal',

  props: {
    label: {
      type: String,
      default: null,
    },

    leftLabel: {
      type: Boolean,
      default: false,
    },

    noLabel: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: null,
    },
  },
};
</script>
