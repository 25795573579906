<template>
  <div class="modal" :class="{ 'is-active': visible }">
    <div class="modal-background" @click="onClose" />

    <div class="modal-content" :class="{ 'modal-content-fullwidth': fullWidth }">
      <div class="modal-head">
        <button type="button" class="modal-close is-large" @click="onClose" />
      </div>

      <div class="modal-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    eventId: {
      type: String,
      required: true,
    },

    fullWidth: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  created() {
    this.$eventBus.on(`${this.eventId}.show`, this.onShow);
  },

  methods: {
    onShow(...args) {
      const e = new Event('show', { cancelable: true });

      this.$emit('show', e, ...args);

      if (!e.defaultPrevented) {
        this.show();
      }
    },

    onClose() {
      const e = new Event('close', { cancelable: true });

      this.$emit('close', e);

      if (!e.defaultPrevented) {
        this.close();
      }
    },

    show() {
      this.visible = true;

      if (!document.documentElement.classList.contains('modal-open')) {
        document.documentElement.classList.add('modal-open');
      }

      this.$nextTick(() => this.$emit('shown'));
    },

    close() {
      this.visible = false;

      if (
        document.documentElement.classList.contains('modal-open') &&
        document.querySelectorAll('.modal.is-active').length === 1
      ) {
        document.documentElement.classList.remove('modal-open');
      }

      this.$nextTick(() => this.$emit('closed'));
    },
  },
};
</script>
