import get from 'lodash/get';
import isNumber from 'lodash/isNumber';

import { isEmpty } from './utils';

export const parseNumber = (value, fallbackValue = null) => {
  if (isEmpty(value)) {
    return fallbackValue;
  }

  const valueParsed = isNumber(value) ? value : parseFloat(value, 10);

  return Number.isNaN(valueParsed) ? fallbackValue : valueParsed;
};

export const formatCurrency = (
  value,
  fractionDigits = 0,
  { locales = 'en-US', currencyCode = 'USD', ...options } = {}
) => {
  const valueParsed = parseNumber(value);

  if (valueParsed === null) {
    return null;
  }

  return new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    ...options,
  }).format(valueParsed);
};

export const formatCurrencyCompact = (value, fractionDigits = 0, options = {}) => {
  const valueParsed = parseNumber(value);

  if (valueParsed === null) {
    return { value: null, symbol: null };
  }

  const groups = [
    { divider: 1, symbol: '' },
    { divider: 1e3, symbol: 'K' },
    { divider: 1e6, symbol: 'M' },
    { divider: 1e9, symbol: 'B' },
  ];

  const matchedGroup = groups.reduce((matched, group) => {
    if (Math.abs(valueParsed) >= group.divider) {
      return group;
    }

    return matched;
  }, groups[0]);

  const compactValue = valueParsed / matchedGroup.divider;

  return formatCurrency(compactValue, fractionDigits, options) + matchedGroup.symbol;
};

export const formatDecimal = (
  value,
  fractionDigits = 0,
  { locales = 'en-US', ...options } = {}
) => {
  const valueParsed = parseNumber(value);

  if (valueParsed === null) {
    return null;
  }

  return new Intl.NumberFormat(locales, {
    style: 'decimal',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    ...options,
  }).format(valueParsed);
};

export const getRatio = (value, total) => {
  const valueParsed = parseNumber(value);

  if (valueParsed === null) {
    return null;
  }

  const totalParsed = parseNumber(total, 0);

  if (totalParsed === 0) {
    if (valueParsed === 0) {
      return 0;
    }

    return valueParsed > 0 ? 1 : -1;
  }

  return valueParsed / totalParsed;
};

export const formatPercent = (value, fractionDigits = 0) => {
  if (value === null) {
    return null;
  }

  const percent = value * 100;

  return `${formatDecimal(percent, fractionDigits)}%`;
};

export const formatPercentFrom = (value, total, fractionDigits = 0) => {
  const ratio = getRatio(value, total);

  return formatPercent(ratio, fractionDigits);
};

export const getTotalOf = (items, key) =>
  items.reduce((total, item) => total + parseNumber(get(item, key, 0), 0), 0);
