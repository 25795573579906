<template>
  <span class="icon">
    <i :class="value"></i>
  </span>
</template>

<script>
export default {
  name: 'BaseIcon',

  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>
