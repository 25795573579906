class Auth {
  constructor(user) {
    this.user = user;

    return new Proxy(this, {
      get: (auth, prop) => {
        if (prop in auth) {
          return auth[prop];
        }

        if (prop in this.user && typeof this.user[prop] === 'function') {
          return (...args) => this.user[prop](...args);
        }

        return undefined;
      },
    });
  }

  setUser(user) {
    this.user.setAttributes(user);
  }

  check() {
    return this.user.isValid();
  }

  guest() {
    return !this.check();
  }

  id() {
    return this.check() ? this.user.id : undefined;
  }

  apiToken() {
    return this.check() ? this.user.api_token : undefined;
  }
}

export default Auth;
