import Vue from 'vue';

import AlgoliaHighlight from './base-components/AlgoliaHighlight.vue';
import BaseBox from './base-components/BaseBox.vue';
import BaseButton from './base-components/BaseButton.vue';
import BaseDialog from './base-components/BaseDialog.vue';
import BaseFooter from './base-components/BaseFooter.vue';
import BaseIcon from './base-components/BaseIcon.vue';
import BaseModal from './base-components/BaseModal.vue';
import BaseNavbar from './base-components/BaseNavbar.vue';
import BaseSection from './base-components/BaseSection.vue';
import BaseTab from './base-components/BaseTab.vue';
import BaseTabs from './base-components/BaseTabs.vue';
import ButtonList from './base-components/ButtonList.vue';
import ColumnGroup from './base-components/ColumnGroup.vue';
import ColumnItem from './base-components/ColumnItem.vue';
import FieldGroup from './base-components/FieldGroup.vue';
import FieldItem from './base-components/FieldItem.vue';
import FieldRow from './base-components/FieldRow.vue';
import FieldTitle from './base-components/FieldTitle.vue';
import FieldValue from './base-components/FieldValue.vue';
import FormField from './base-components/FormField.vue';
import FormFieldHorizontal from './base-components/FormFieldHorizontal.vue';
import InputCurrency from './base-components/InputCurrency.vue';
import InputDate from './base-components/InputDate.vue';
import InputEin from './base-components/InputEin.vue';
import InputField from './base-components/InputField.vue';
import InputFile from './base-components/InputFile.vue';
import InputFileList from './base-components/InputFileList.vue';
import InputMask from './base-components/InputMask.vue';
import InputPhone from './base-components/InputPhone.vue';
import InputRadioGroup from './base-components/InputRadioGroup.vue';
import InputRegion from './base-components/InputRegion.vue';
import InputRegionState from './base-components/InputRegionState.vue';
import InputSelect from './base-components/InputSelect.vue';
import InputTextarea from './base-components/InputTextarea.vue';
import InputZip from './base-components/InputZip.vue';
import LinkEmail from './base-components/LinkEmail.vue';
import LinkPhone from './base-components/LinkPhone.vue';
import LoadingSpinner from './base-components/LoadingSpinner.vue';
import MayEmpty from './base-components/MayEmpty.vue';
import NavbarDivider from './base-components/NavbarDivider.vue';
import NavbarDropdown from './base-components/NavbarDropdown.vue';
import NavbarItem from './base-components/NavbarItem.vue';
import NavbarItemDropdown from './base-components/NavbarItemDropdown.vue';
import NotificationBox from './base-components/NotificationBox.vue';
import TabItem from './base-components/TabItem.vue';
import TabList from './base-components/TabList.vue';
import ToggleButtons from './base-components/ToggleButtons.vue';
import WithLoading from './base-components/WithLoading.vue';

Vue.component('algolia-highlight', AlgoliaHighlight);
Vue.component('base-box', BaseBox);
Vue.component('base-button', BaseButton);
Vue.component('base-dialog', BaseDialog);
Vue.component('base-footer', BaseFooter);
Vue.component('base-icon', BaseIcon);
Vue.component('base-modal', BaseModal);
Vue.component('base-navbar', BaseNavbar);
Vue.component('base-section', BaseSection);
Vue.component('base-tab', BaseTab);
Vue.component('base-tabs', BaseTabs);
Vue.component('button-list', ButtonList);
Vue.component('column-group', ColumnGroup);
Vue.component('column-item', ColumnItem);
Vue.component('field-group', FieldGroup);
Vue.component('field-item', FieldItem);
Vue.component('field-row', FieldRow);
Vue.component('field-title', FieldTitle);
Vue.component('field-value', FieldValue);
Vue.component('form-field', FormField);
Vue.component('form-field-horizontal', FormFieldHorizontal);
Vue.component('input-currency', InputCurrency);
Vue.component('input-date', InputDate);
Vue.component('input-ein', InputEin);
Vue.component('input-field', InputField);
Vue.component('input-file', InputFile);
Vue.component('input-file-list', InputFileList);
Vue.component('input-mask', InputMask);
Vue.component('input-phone', InputPhone);
Vue.component('input-radio-group', InputRadioGroup);
Vue.component('input-region', InputRegion);
Vue.component('input-region-state', InputRegionState);
Vue.component('input-select', InputSelect);
Vue.component('input-textarea', InputTextarea);
Vue.component('input-zip', InputZip);
Vue.component('link-email', LinkEmail);
Vue.component('link-phone', LinkPhone);
Vue.component('loading-spinner', LoadingSpinner);
Vue.component('may-empty', MayEmpty);
Vue.component('navbar-divider', NavbarDivider);
Vue.component('navbar-dropdown', NavbarDropdown);
Vue.component('navbar-item', NavbarItem);
Vue.component('navbar-item-dropdown', NavbarItemDropdown);
Vue.component('notification-box', NotificationBox);
Vue.component('tab-item', TabItem);
Vue.component('tab-list', TabList);
Vue.component('toggle-buttons', ToggleButtons);
Vue.component('with-loading', WithLoading);
