<template>
  <div v-if="!!value" :class="`notification${type ? ` is-${type}` : ''}`">
    <button
      v-if="dismissible"
      type="button"
      class="delete"
      @click="
        () => {
          $emit('input', null);
          $emit('dismiss');
        }
      "
    />
    <slot>
      {{ value }}
    </slot>
  </div>
</template>

<script>
export default {
  name: 'NotificationBox',

  props: {
    value: {
      type: [Boolean, String],
      default: true,
    },

    dismissible: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: '',
    },
  },
};
</script>
