<template>
  <div
    :disabled="disabled"
    :class="{
      'has-name': showFilename,
      'is-fullwidth': fullwidth,
      'is-danger': hasError,
    }"
    class="file"
  >
    <label class="file-label">
      <input
        v-if="inputReady"
        v-bind="attrs"
        v-on="listeners"
        :disabled="disabled"
        :multiple="multiple"
        type="file"
        class="file-input"
        @change="onChange"
      />

      <span class="file-cta">
        <span class="file-icon">
          <i class="fas fa-upload"></i>
        </span>
        <span class="file-label">
          {{ label }}
        </span>
      </span>

      <span v-if="showFilename" :class="{ 'file-name-placeholder': isEmpty }" class="file-name">
        {{ filenames }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: [Boolean, String],
      default: false,
    },

    label: {
      type: String,
      default: 'Choose File',
    },

    fullwidth: {
      type: Boolean,
      default: false,
    },

    showFilename: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: 'No file choosen',
    },
  },

  data() {
    return {
      files: [],
      inputReady: true,
    };
  },

  computed: {
    attrs() {
      const { disabled, multiple, ...attrs } = this.$attrs;

      return attrs;
    },

    listeners() {
      const { change, ...listeners } = this.$listeners;

      return listeners;
    },

    isEmpty() {
      return this.files.length === 0;
    },

    filenames() {
      return this.isEmpty ? this.placeholder : this.files.map((file) => file.name).join(', ');
    },
  },

  methods: {
    onChange(e) {
      this.files = [...e.target.files];

      this.$emit('change', e);

      if (!this.isEmpty) {
        this.$emit('select', this.multiple ? this.files : this.files[0]);
      }
    },

    reset() {
      if (this.isEmpty) {
        return;
      }

      this.files = [];

      // Remove and add back the input file to reset the value.
      this.inputReady = false;
      this.$nextTick(() => (this.inputReady = true));
    },
  },
};
</script>
