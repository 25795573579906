import Vue from 'vue';
import get from 'lodash/get';

import {
  formatCurrency,
  formatCurrencyCompact,
  formatDecimal,
  formatPercent,
  formatPercentFrom,
  getTotalOf,
} from './utils/numbers';

import { formatDate, formatApiDate, dateFromNow, apiDateFromNow } from './utils/date';

import {
  url,
  oldAdminUrl,
  adminApiUrl,
  dotnetJbaApiUrl,
  publicApiUrl,
  lgdfsApiUrl,
  meusApiUrl,
  meusCommercialApiUrl,
  docUrl,
  lgdfsUrl,
  meusUrl,
} from './utils/url';

import { formatPhone } from './utils/utils';

Vue.filter('get', get);

Vue.filter('currency', formatCurrency);
Vue.filter('currencyCompact', formatCurrencyCompact);
Vue.filter('decimal', formatDecimal);
Vue.filter('percent', formatPercent);
Vue.filter('percentFrom', formatPercentFrom);
Vue.filter('totalOf', getTotalOf);

Vue.filter('date', formatDate);
Vue.filter('apiDate', formatApiDate);
Vue.filter('dateFromNow', dateFromNow);
Vue.filter('apiDateFromNow', apiDateFromNow);

Vue.filter('url', url);
Vue.filter('oldAdminUrl', oldAdminUrl);
Vue.filter('adminApiUrl', adminApiUrl);
Vue.filter('dotnetJbaApiUrl', dotnetJbaApiUrl);
Vue.filter('publicApiUrl', publicApiUrl);
Vue.filter('lgdfsApiUrl', lgdfsApiUrl);
Vue.filter('meusApiUrl', meusApiUrl);
Vue.filter('meusCommercialApiUrl', meusCommercialApiUrl);
Vue.filter('docUrl', docUrl);
Vue.filter('lgdfsUrl', lgdfsUrl);
Vue.filter('meusUrl', meusUrl);

Vue.filter('phone', formatPhone);
