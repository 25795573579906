import get from 'lodash/get';
import moment from 'moment';
import { parseApiDate } from '@/utils/date';

/**
 * Change ratio container's class name.
 *
 * @param {Number} ratio
 * @return {String}
 */
const changeRatioContainerClassName = (ratio) => {
  if (ratio > 0) {
    return 'bg-success-subtle';
  }

  if (ratio < 0) {
    return 'bg-danger-subtle';
  }

  return 'bg-light';
};

/**
 * Format the phone.
 *
 * @param  {Mixed}
 * @return {Mixed}
 */
const formatPhone = (phone) => {
  if (typeof phone !== 'string') {
    return phone;
  }

  const parts = phone.match(/(\d{3})[-]?(\d{3})[-]?(\d{4})/);

  if (!Array.isArray(parts)) {
    return phone;
  }

  return `${parts[1]}-${parts[2]}-${parts[3]}`;
};

/**
 * Get the change ratio.
 *
 * @param {Number} options.current
 * @param {Number} options.previous
 * @return {Number}
 */
const getChangeRatio = ({ current, previous }) => {
  if (previous === 0) {
    if (current === 0) {
      return 0;
    }

    return current > 0 ? 1 : -1;
  }

  return (current - previous) / previous;
};

/**
 * Get the current year.
 *
 * @return {Number}
 */
const getCurrentYear = () => new Date().getFullYear();

/**
 * Get dealer facebook search url.
 *
 * @param {String} dealer
 * @param {String} city
 * @param {String} state
 * @return {String}
 */
const getDealerFacebookSearchUrl = ({ dealer, city, state }) =>
  getFacebookSearchUrl(`${dealer}, ${city} ${state}`);

/**
 * Get dealer google maps search url.
 *
 * @param {String} dealer
 * @param {String} city
 * @param {String} state
 * @return {String}
 */
const getDealerGoogleMapsSearchUrl = ({ dealer, city, state }) =>
  getGoogleMapsSearchUrl(`${dealer}, ${city} ${state}`);

/**
 * Get dealer google search url.
 *
 * @param {String} dealer
 * @param {String} city
 * @param {String} state
 * @return {String}
 */
const getDealerGoogleSearchUrl = ({ dealer, city, state }) =>
  getGoogleSearchUrl(`${dealer}, ${city} ${state}`);

/**
 * Get facebook search url.
 *
 * @param {String} query
 * @return {String}
 */
const getFacebookSearchUrl = (query) =>
  `https://www.facebook.com/search?q=${encodeURIComponent(query)}`;

/**
 * Get google maps search url.
 *
 * @param {String} query
 * @return {String}
 */
const getGoogleMapsSearchUrl = (query) =>
  `https://www.google.com/maps/search/${encodeURIComponent(query)}`;

/**
 * Get google search url.
 *
 * @param {String} query
 * @return {String}
 */
const getGoogleSearchUrl = (query) =>
  `https://www.google.com/search?q=${encodeURIComponent(query)}`;

/**
 * Get the ratio of some value (ofValue) out of the total (fromTotal).
 *
 * @param {Number} options.ofValue
 * @param {Number} options.fromTotal
 * @return {Number}
 */
const getRatio = ({ ofValue, fromTotal }) => {
  if (fromTotal === 0) {
    if (ofValue === 0) {
      return 0;
    }

    return ofValue > 0 ? 1 : -1;
  }

  return ofValue / fromTotal;
};

/**
 * Get the total of the given property.
 *
 * @param {Array} items
 * @param {String} property
 * @return {Number}
 */
const getTotalOf = (items, property) =>
  items.reduce((total, item) => {
    const value = parseFloat(get(item, property, 0));

    return isNaN(value) ? total : total + value;
  }, 0);

/**
 * Check whether the given value is an array.
 *
 * @param {Mixed} value
 * @return {Boolean}
 */
const isArray = (value) => Array.isArray(value);

/**
 * Check if the given value is empty.
 *
 * @param {Mixed} value
 * @return {Boolean}
 */
const isEmpty = (value) => {
  if (undefined === value) {
    return true;
  }

  if (null === value) {
    return true;
  }

  if (isString(value)) {
    return value.trim().length === 0;
  }

  if (isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0 && value.constructor === Object;
  }

  return false;
};

/**
 * Check whether the given value is a function.
 *
 * @param {Mixed} value
 * @return {Boolean}
 */
const isFunction = (value) => typeof value === 'function';

/**
 * Check whether the given value is an object.
 *
 * @param {Mixed} value
 * @return {Boolean}
 */
const isObject = (value) => {
  if (typeof value !== 'object') {
    return false;
  }

  if (null === value) {
    return false;
  }

  return !isArray(value);
};

/**
 * Check whether the given value is a string.
 *
 * @param {Mixed} value
 * @return {Boolean}
 */
const isString = (value) => typeof value === 'string';

/**
 * Shorten the name.
 *
 * @param {String} fullName
 * @return {String}
 */
const shortenName = (fullName) => {
  const [first, ...last] = fullName.split(' ');

  const lastInitials = last.map((name) => name.charAt(0)).join('');

  return `${first}${lastInitials}`;
};

/**
 * Zero or not zero container's class name.
 *
 * @param {Number} value
 * @return {String}
 */
const zeroOrNotContainerClassName = (value) => {
  if (value > 0) {
    return 'bg-success-subtle';
  }

  return 'bg-danger-subtle';
};

export {
  changeRatioContainerClassName,
  formatPhone,
  getChangeRatio,
  getCurrentYear,
  getDealerFacebookSearchUrl,
  getDealerGoogleMapsSearchUrl,
  getDealerGoogleSearchUrl,
  getFacebookSearchUrl,
  getGoogleMapsSearchUrl,
  getGoogleSearchUrl,
  getRatio,
  getTotalOf,
  isArray,
  isEmpty,
  isFunction,
  isObject,
  isString,
  shortenName,
  zeroOrNotContainerClassName,
};
