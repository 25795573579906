<template>
  <div :class="{ 'is-right': right, 'is-boxed': boxed }" class="navbar-dropdown">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'NavbarDropdown',

  props: {
    right: {
      type: Boolean,
      default: false,
    },

    boxed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
