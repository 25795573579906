<template>
  <div class="control">
    <label v-for="option in options" :key="option.value" class="radio">
      <input v-model="model" v-bind="$attrs" :value="option.value" type="radio" /> {{ option.text }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'InputRadioGroup',

  inheritAttrs: false,

  props: {
    value: {
      type: [Boolean, Number, String],
      default: undefined,
    },

    options: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      model: this.value,
    };
  },

  watch: {
    value(val) {
      this.model = val;
    },

    model(val) {
      this.$emit('input', val);
    },
  },
};
</script>
