<template>
  <div :class="{ 'expand-dropdown': active }" class="navbar-item has-dropdown is-hoverable">
    <navbar-item :href="$attrs.href" :link="topLevel" @click="toggle">
      <slot name="title">
        {{ title }}
      </slot>
    </navbar-item>
    <navbar-dropdown :boxed="!topLevel" :right="right">
      <navbar-item v-if="isLink" :href="$attrs.href" class="is-hidden-desktop">
        {{ title }}
      </navbar-item>
      <slot />
    </navbar-dropdown>
  </div>
</template>

<script>
import { isMobile } from '@/utils/utils';

export default {
  name: 'NavbarItemDropdown',

  props: {
    title: {
      type: String,
      default: '',
    },

    topLevel: {
      type: Boolean,
      default: false,
    },

    right: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      active: false,
    };
  },

  computed: {
    isLink() {
      return this.$attrs.hasOwnProperty('href') && typeof this.$attrs.href === 'string';
    },
  },

  methods: {
    toggle(e) {
      if (isMobile()) {
        e.preventDefault();
      }

      this.active = !this.active;
    },
  },
};
</script>
