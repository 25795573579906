<template>
  <div>
    <portal v-if="portalTo" :to="portalTo">
      <div class="tabs" :class="classList">
        <ul>
          <li v-for="item in items" :key="item.id" :class="{ 'is-active': item.active }">
            <a @click="select(item.id)">
              <span>{{ item.title }}</span>
              <span v-if="item.hasBadge" :class="`tag is-${item.badgeColor} is-rounded`">
                {{ item.badge }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </portal>
    <div v-else class="tabs" :class="classList">
      <ul>
        <li v-for="item in items" :key="item.id" :class="{ 'is-active': item.active }">
          <a @click="select(item.id)">
            <span>{{ item.title }}</span>
            <span v-if="item.hasBadge" :class="`tag is-${item.badgeColor} is-rounded`">
              {{ item.badge }}
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
import queryString from 'query-string';

export default {
  name: 'TabList',

  props: {
    defaultTabId: {
      type: String,
      default: null,
    },

    size: {
      type: String,
      default: 'normal',
      validator: (value) => ['smaller', 'small', 'normal', 'medium', 'large'].includes(value),
    },

    type: {
      type: String,
      validator: (value) => ['boxed', 'toggle', 'toggle-rounded'].includes(value),
    },

    urlFragmentKey: {
      type: String,
      default: 'tab',
    },

    useUrlFragment: {
      type: Boolean,
      default: false,
    },

    portalTo: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      items: [],
    };
  },

  computed: {
    classList() {
      const classes = [`is-${this.size}`];

      if (this.type) {
        classes.push(`is-${this.type}`);
      }

      if (this.type === 'toggle-rounded') {
        classes.push('is-toggle');
      }

      return classes;
    },

    computedDefaultTabId() {
      return this.defaultTabId ? this.defaultTabId : this.items[0].id;
    },
  },

  mounted() {
    this.items = this.$children.filter(($item) => !$item.$el.classList.contains('v-portal'));

    if (this.useUrlFragment) {
      window.addEventListener('hashchange', this.onUrlFragmentChange);
    }

    this.items.forEach((item) => (item.active = item.id === this.getDefaultActiveTabId()));
  },

  destroyed() {
    if (this.useUrlFragment) {
      window.removeEventListener('hashchange', this.onUrlFragmentChange);
    }
  },

  methods: {
    getDefaultActiveTabId() {
      if (!this.useUrlFragment) {
        return this.computedDefaultTabId;
      }

      const tabIdFromUrlFragment = this.getTabIdFromUrlFragment();

      return tabIdFromUrlFragment ? tabIdFromUrlFragment : this.computedDefaultTabId;
    },

    getTabIdFromUrlFragment() {
      return queryString.parse(window.location.hash)[this.urlFragmentKey];
    },

    onUrlFragmentChange() {
      this.select(this.getDefaultActiveTabId(), { updateUrlFragment: false });
    },

    select(id, { updateUrlFragment = true } = {}) {
      this.items.forEach((item) => {
        if (item.id === id) {
          item.active = true;

          if (this.useUrlFragment && updateUrlFragment) {
            this.updateUrlFragment(item.id);
          }

          this.$emit('change', item);
        } else {
          item.active = false;
        }
      });
    },

    updateUrlFragment(id) {
      const fragment = {
        ...queryString.parse(window.location.hash),
        [this.urlFragmentKey]: id,
      };

      window.location.hash = queryString.stringify(fragment);
    },
  },
};
</script>

<style scoped>
.tag {
  margin-left: 5px;
}
</style>
