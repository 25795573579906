<template>
  <div class="navbar is-primary">
    <div class="container">
      <div class="navbar-brand">
        <a :href="logoUrl" class="navbar-item navbar-brand-logo">
          <slot name="logo">
            <h1>Home</h1>
          </slot>
        </a>

        <div :class="{ 'is-active': isActive }" class="navbar-burger" @click="toggle">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div :class="{ 'is-active': isActive }" class="navbar-menu">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseNavbar',

  props: {
    logoUrl: {
      type: String,
      default: '/',
    },
  },

  data() {
    return {
      isActive: false,
    };
  },

  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
  },
};
</script>
