<template>
  <field-row>
    <field-title :class="titleClass" :left-label="leftLabel">
      {{ title }}
    </field-title>
    <field-value>
      <slot />
    </field-value>
  </field-row>
</template>

<script>
export default {
  name: 'FieldItem',

  props: {
    title: {
      type: String,
      default: '',
    },

    titleClass: {
      type: String,
      default: '',
    },

    leftLabel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
