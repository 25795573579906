export const url = (path = '', { baseUrl = import.meta.env.VITE_BASE_URL } = {}) => {
  const p = path.substring(0, 1) === '/' ? path.substring(1) : path;

  return `${baseUrl}/${p}`;
};

export const oldAdminUrl = (path = '') =>
  url(path, { baseUrl: import.meta.env.VITE_OLD_ADMIN_URL });

export const oldAdminApiUrl = (path = '') =>
  url(path, { baseUrl: `${import.meta.env.VITE_OLD_ADMIN_URL}/api` });

export const adminApiUrl = (path = '') =>
  url(path, { baseUrl: `${import.meta.env.VITE_ADMIN_API_URL}/api` });

export const dotnetJbaApiUrl = (path = '') =>
  url(path, { baseUrl: import.meta.env.VITE_DOTNET_JBA_API_URL });

export const publicApiUrl = (path = '') =>
  url(path, { baseUrl: import.meta.env.VITE_PUBLIC_API_URL });

export const lgdfsApiUrl = (path = '') =>
  url(path, { baseUrl: import.meta.env.VITE_LGDFS_API_URL });

export const meusApiUrl = (path = '') => url(path, { baseUrl: import.meta.env.VITE_MEUS_API_URL });

export const meusCommercialApiUrl = (path = '') =>
  url(path, { baseUrl: import.meta.env.VITE_MEUS_COMMERCIAL_API_URL });

export const docUrl = (path = '') => url(path, { baseUrl: import.meta.env.VITE_DOC_URL });

export const partnersUrl = (path = '') => url(path, { baseUrl: import.meta.env.VITE_PARTNERS_URL });

export const lgdfsUrl = (path = '') => url(path, { baseUrl: import.meta.env.VITE_LGDFS_URL });

export const meusUrl = (path = '') => url(path, { baseUrl: import.meta.env.VITE_MEUS_URL });
