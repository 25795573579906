import { ref } from 'vue';
import { defineStore } from 'pinia';
import client from '../utils/client.js';

export const useRegionsStore = defineStore('regions', () => {
  const regions = ref([]);
  const regionGroups = ref([]);

  async function load() {
    const res = await Promise.all([client.get('/api/regions'), client.get('/api/region-groups')]);

    regions.value = res[0].data.data;
    regionGroups.value = res[1].data.data;

    return res;
  }

  const getById = (id) =>
    regions.value.find((region) => region.regionid === Number.parseInt(id, 10));

  return { regions, regionGroups, load, getById };
});
