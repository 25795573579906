import axios from 'axios';
import { get } from 'lodash';
import { useAuthStore } from '../stores/auth';

export const createClient = ({
  baseURL = import.meta.env.VITE_ADMIN_API_URL,
  bearerTokenField = 'api_token',
} = {}) => {
  const client = axios.create({
    baseURL,
    headers: {
      Accept: 'application/json',
    },
  });

  client.interceptors.request.use(
    (config) => {
      const auth = useAuthStore();

      if (auth.isLoggedIn && !config.headers.hasAuthorization()) {
        config.headers.setAuthorization(`Bearer ${auth.user[bearerTokenField]}`);
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (res) => res,
    (error) => {
      const status = get(error, 'response.status');
      const message = get(error, 'response.data.message');

      if (status === 401) {
        const auth = useAuthStore();

        if (auth.isLoggedIn) {
          auth.logout();

          window.location = '/login';
        }
      }

      if (message) {
        error.message = message;
      }

      return Promise.reject(error);
    }
  );

  return client;
};

const client = createClient();

export const clientLgdfs = createClient({
  baseURL: import.meta.env.VITE_LGDFS_API_URL,
  bearerTokenField: 'lgdfs_api_token',
});

export const clientMeus = createClient({
  baseURL: import.meta.env.VITE_MEUS_API_URL,
  bearerTokenField: 'meus_api_token',
});

export default client;
