import algoliasearch from 'algoliasearch/lite';

class AlgoliaSearch {
  constructor({ applicationId, apiKey }) {
    this.client = algoliasearch(applicationId, apiKey);

    this.initializedIndices = {};
  }

  getIndex(name) {
    if (!this.initializedIndices.hasOwnProperty(name)) {
      this.initializedIndices[name] = this.client.initIndex(name);
    }

    return this.initializedIndices[name];
  }
}

export default AlgoliaSearch;
