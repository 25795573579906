import {
  url,
  oldAdminUrl,
  oldAdminApiUrl,
  adminApiUrl,
  dotnetJbaApiUrl,
  publicApiUrl,
  lgdfsApiUrl,
  meusApiUrl,
  meusCommercialApiUrl,
  docUrl,
  partnersUrl,
  lgdfsUrl,
  meusUrl,
} from '../utils/url';

export default {
  methods: {
    urlFor: url,
    oldAdminUrl,
    oldAdminApiUrl,
    adminApiUrl,
    dotnetJbaApiUrl,
    publicApiUrl,
    lgdfsApiUrl,
    meusApiUrl,
    meusCommercialApiUrl,
    docUrl,
    partnersUrl,
    lgdfsUrl,
    meusUrl,
  },
};
