import remove from 'lodash/remove';

const MAX_ITEMS = 50;

class ViewHistory {
  constructor() {
    this.userId = undefined;
    this.items = this.getAllFromStorage();
  }

  setUserId(id) {
    this.userId = id;
    this.items = this.getAllFromStorage();
  }

  storageKey() {
    return this.userId ? `view_history.${this.userId}` : 'view_history.guest';
  }

  getAllFromStorage() {
    try {
      const items = JSON.parse(window.localStorage.getItem(this.storageKey()));

      return items === null ? [] : items;
    } catch (e) {
      return [];
    }
  }

  addAgreement(item) {
    return this.add('agreement', item);
  }

  addClaim(item) {
    return this.add('claim', item);
  }

  addDealer(item) {
    return this.add('dealer', item);
  }

  addLead(item) {
    return this.add('lead', item);
  }

  add(type, item) {
    remove(this.items, (i) => i.type === type && i.id === item.id);

    this.items.unshift({
      ...item,
      type,
      visited_at: new Date(),
    });

    this.items.splice(MAX_ITEMS);

    window.localStorage.setItem(this.storageKey(), JSON.stringify(this.items));

    return this;
  }

  clear() {
    this.items = [];

    window.localStorage.setItem(this.storageKey(), JSON.stringify(this.items));

    return this;
  }
}

export default ViewHistory;
