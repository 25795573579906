<template>
  <div class="section">
    <div class="container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSection',
};
</script>
