<template>
  <div class="buttons has-addons">
    <button
      v-for="option in options"
      :key="option.value"
      :class="classForValue(option.value)"
      type="button"
      class="button"
      @click="click(option.value)"
    >
      {{ option.text }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ToggleButtons',

  props: {
    value: {
      type: [Number, String],
      default: '',
    },

    options: {
      type: Array,
      default() {
        return [];
      },
    },

    allowEmpty: {
      type: Boolean,
      default: false,
    },

    emptyValue: {
      type: [Number, String],
      default: '',
    },
  },

  methods: {
    classForValue(value) {
      return value === this.value ? 'is-primary is-selected' : null;
    },

    click(value) {
      if (value === this.value) {
        if (this.allowEmpty) {
          this.$emit('input', this.emptyValue);
        }
      } else {
        this.$emit('input', value);
      }
    },
  },
};
</script>
