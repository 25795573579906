<template>
  <span v-if="hasHighlight" v-html="item._highlightResult[prop].value" class="algolia-highlight" />
  <may-empty v-else :value="item[prop]" />
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },

    prop: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasHighlight() {
      return (
        this.item._highlightResult.hasOwnProperty(this.prop) &&
        ['full', 'partial'].includes(this.item._highlightResult[this.prop].matchLevel)
      );
    },
  },
};
</script>

<style>
.algolia-highlight em {
  background: #ffdd57;
}
</style>
