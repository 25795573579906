<template>
  <div v-if="lazy">
    <div v-if="active">
      <slot />
    </div>
  </div>
  <div v-else>
    <div v-show="active">
      <slot />
    </div>
  </div>
</template>

<script>
import kebabCase from 'lodash/kebabCase';

export default {
  name: 'TabItem',

  props: {
    badge: {
      type: [String, Number],
      default: '',
    },

    badgeColor: {
      type: String,
      default: 'light',
    },

    title: {
      type: String,
      required: true,
    },

    lazy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      active: false,
    };
  },

  watch: {
    active(val) {
      this.$nextTick(() => (val ? this.$emit('shown') : this.$emit('hidden')));
    },
  },

  computed: {
    id() {
      return kebabCase(this.title);
    },

    hasBadge() {
      if (this.badge === undefined || this.badge === null) {
        return false;
      }

      if (typeof this.badge === 'string') {
        return this.badge.trim().length > 0;
      }

      return true;
    },
  },
};
</script>
